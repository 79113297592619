import React, { Component } from 'react';

import {Layout, Col, Card, Row, Tooltip, Divider} from 'antd';
import Ellipsis from 'react-ellipsis-pjs';
import {FacebookFilled, TwitterSquareFilled, LinkedinFilled} from '@ant-design/icons';
import './index.scss';
import Core from "../../Core.js";
const { Meta } = Card;

export default class Speakers extends Component {



    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: []
        };
    }

    componentDidMount() {
        this.load();
    }

    load() {
        this.setState({ loading: true });
        Core.apiData(`speakers/list.json`, {
              onSuccess: (data) => {
                  if (data.json) {
                      this.setState({
                        data: data.json,
                      });
                  } else {
                      this.setState({ error: true });
                      console.log("Speakers data is not a JSON!");
                  }
              },
              onFail: (data) => {
                  this.setState({ error: true });
                  console.log("Speakers data failed.", data);
              }
          });
          this.setState({ loading: false });
    }

    render() {
/*        return (
            <div className="speakers-container">
                <Layout>
                    <div>
                        <Row>
                            { this.state.data.map(speaker => {
                                if((speaker.highlight && this.props.display) || !this.props.display) {
                                   return(  <Col className="col" lg={6} md={10} sm={16}>
                                                <Card
                                                    className="col-card"
                                                    cover={<div className="col-card-img" style={{backgroundImage: `url(${"/data/speakers/" + speaker.uid + ".jpg"})`}} />}
                                                    >
                                                    <h4 className="h4-name">{speaker.name}</h4>
                                                    <h5 className="h5-description">{speaker.description}</h5>
                                                </Card>
                                            </Col> );
                                }
                                return null;
                            })}
                        </Row>
                    </div>
                </Layout>
            </div>
            );*/
        const speakers = [];
        const bigSpeakers = [];

        for (const speaker of this.state.data) {
            speakers.push(
                <li id = {speaker.name} className="speaker__container">
                  <div className="speaker">
                    <div className="speaker__photo">
                      <div style={{backgroundImage: `url(${"/data/speakers/" + speaker.uid + ".jpg"})`}}></div>
                    </div>
                    <h3 className="speaker__name">{speaker.name}</h3>
                    <p className="speaker__description">{speaker.description}</p>
                  </div>
                </li>
            );
            bigSpeakers.push(
              <Col xs={24} sm={24} md={12} lg={8} xl={8} id={speaker.name} className="big-speaker" style={{backgroundImage: `url(${"/data/speakers/" + speaker.uid + ".jpg"})` }} >
                  <div className="big-speaker__content">
                    <h3 className="name">{speaker.name}</h3>
                    <p className="title">{speaker.title}</p>
                    <div className="description" >
                      <Ellipsis
                        text={speaker.description}
                        lines={3}
                        suffix="..."
                        render={(ellipsisText, isEllipsis) => {
                          if (!isEllipsis) return(
                            <p className="description">{ellipsisText}</p>
                          );
                          return (
                            <Tooltip  placement="topLeft" className="tooltip-description" title={speaker.description}><p className="description">{ellipsisText}</p></Tooltip>
                          );
                        }}
                      />
                    </div>
                    <div className="social-media">
                      {speaker.linked_in.length > 0 && <a href={speaker.linked_in} target="_blank"><LinkedinFilled /></a>}
                      {speaker.facebook.length > 0 && <a href={speaker.facebook} target="_blank"><FacebookFilled /></a>}
                      {speaker.twitter.length > 0 && <a href={speaker.twitter} target="_blank"><TwitterSquareFilled /></a>}
                    </div>
                  </div>

              </Col>
            )


        }
        return (
            <div className="speakers-container">
              <div className="title-red-card title-red-card-top">
                <span>Palestrante</span>
              </div>
              <Row className="event-speakers">
                  <Col xs={{ span: 24, offset: 0 }} sm={{ span: 6, offset: 0 }} className="big-speaker" style={{backgroundImage: `url(${"/data/images/191-Perpetua-Gomes.jpg"})` }}>
                    <div className="big-speaker__content">
                      <h3 className="name">Prof.ª Perpétua Gomes</h3>
                      <p className="title">Centro Hospitalar Lisboa <br/>Ocidental Instituto Universitário Egas Moniz</p>
                    </div>
                  </Col>
                  <Col xs={{ span: 24, offset: 0 }} sm={{ span: 16, offset: 0 }} className="event-speakers__description">
                      <p>A Prof.ª Perpétua Gomes é doutorada em Farmácia na área da Microbiologia pela Universidade de Lisboa (2001). Desde 1996, que colabora como docente no Instituto Universitário Egas Moniz (IUEM), tendo desempenhando a função de coordenadora do Curso de Mestrado Integrado em Ciências Farmacêuticas entre Outubro de 2013 e Setembro de 2019. Paralelamente, desde 1998, colabora com o laboratório de biologia molecular do Centro Hospitalar Lisboa Ocidental – Hospital Egas Moniz (CHLO-HEM), sendo atualmente, responsável pelo Laboratório de Biologia Molecular, LMCBM, SPC.</p>
                      <p>Tem colaborado em diversos projetos de investigação científica, nacionais e internacionais, na área da infeção por VIH. É autora e co-autora de mais de 50 artigos relevantes em revistas científicas internacionais e realizou mais de 50 comunicações orais, maioritariamente na área da infeção por VIH.</p>
                  </Col>
              </Row>
              <div className="title-red-card title-red-card-top">
                <span>Moderadores</span>
              </div>
              <Row justify="center" className="big-speakers">
                { bigSpeakers }
              </Row>
              {/* <ul className="speakers">
                 { speakers }
               </ul> */}
            </div>
        );
    }
}
