import React, { Component } from 'react';
import { unmountComponentAtNode, render } from "react-dom";
import { Layout, Typography, Row, Col, Divider, Button, Modal } from 'antd';
import PDFViewer from 'pdf-viewer-reactjs'

import { Redirect } from "react-router-dom";

import Core from "../../Core.js";

import './index.scss';

const { Title } = Typography;

const target = {
  modal1:
  {
    link: window.location.origin + "/data/images/materiais/CasoClinico1.pdf",
    title: "Caso clínico I"
  },
  modal2:
  {
    link: window.location.origin + "/data/images/materiais/CasoClinico2.pdf",
    title: "Caso clínico II"
  },
  modal3:
  {
    link: window.location.origin + "/data/images/materiais/CasoClinico3.pdf",
    title: "Caso clínico III"
  },
  modal4:
  {
    link: window.location.origin + "/data/images/materiais/CasoClinico4.pdf",
    title: "Caso clínico IV"
  },
  modal5:
  {
    link: window.location.origin + "/data/images/materiais/CasoClinico5.pdf",
    title: "Caso clínico V"
  }
};

export default class SupportMaterials extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      visible: false,
      modal: target.modal1
    };
    this.verifyLogin = this.verifyLogin.bind(this);
    this.showModal = this.showModal.bind(this);
    this.pdf = React.createRef();

  }

  componentDidMount() {
    this.verifyLogin();
  }


  verifyLogin() {
    if (!Core.data().login) {
      this.setState({ redirect: true });
    }
  }

  showModal = (option) => {
    this.setState({
      visible: true,
      modal: option
    });
  };

  hideModal = () => {
    //unmountComponentAtNode(this.pdf.getDOMNode());

    this.setState({
      visible: false,
    });
    //Modal.destroyAll();

  };

  render() {
    const { redirect } = this.state;

    let modal = target.modal1;

    if (redirect) {
      if (Core.data().config.login == true) {
        return <Redirect to="/login?materiais-de-apoio" />;
      }
      return <Redirect to="/" />;
    }

    return (
      <Layout className="support-materials">
        <div className="materials-container">
          <h1>Materiais de Apoio</h1>
          <Row className="case-list">
            <Col sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} xl={{ span: 8, offset: 0 }}>
              <div className="case-image" style={{ backgroundImage: `url(${"/data/images/caso1.jpg"})` }} />
            </Col>
            <Col sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} xl={{ span: 16, offset: 0 }}>
              <div className="case-description">
                <h3 className="case-name">Caso clínico I</h3>
                <p className="case-title">Moderação: Dr.ª Cristina Valente</p>
                <p className="case-location">Centro Hospitalar Universitário de Coimbra</p>
              </div>
              <div className="case-button-wrapper">
                <Button className="case-button" type="primary" size="large" target="_blank" onClick={() => this.showModal(target.modal1)}>CLIQUE AQUI</Button>
              </div>
            </Col>
            <Divider />
            <Col sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} xl={{ span: 8, offset: 0 }}>
              <div className="case-image" style={{ backgroundImage: `url(${"/data/images/caso2.jpg"})` }} />
            </Col>
            <Col sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} xl={{ span: 16, offset: 0 }}>
              <div className="case-description">
                <h3 className="case-name">Caso clínico II</h3>
                <p className="case-title">Moderação: Dr. Miguel Abreu</p>
                <p className="case-location">Centro Hospitalar Universitário do Porto</p>
              </div>
              <div className="case-button-wrapper">
                <Button className="case-button" type="primary" size="large" target="_blank" onClick={() => this.showModal(target.modal2)}>CLIQUE AQUI</Button>
              </div>
            </Col>
            <Divider />
            <Col sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} xl={{ span: 8, offset: 0 }}>
              <div className="case-image" style={{ backgroundImage: `url(${"/data/images/caso3.jpg"})` }} />
            </Col>
            <Col sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} xl={{ span: 16, offset: 0 }}>
              <div className="case-description">
                <h3 className="case-name">Caso clínico III</h3>
                <p className="case-title">Moderação: Dr.ª Raquel Pinho</p>
                <p className="case-location">Centro Hospitalar Universitário do Algarve</p>
              </div>
              <div className="case-button-wrapper">
                <Button className="case-button" type="primary" size="large" target="_blank" onClick={() => this.showModal(target.modal3)}>CLIQUE AQUI</Button>
              </div>
            </Col>
            <Divider />
            <Col sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} xl={{ span: 8, offset: 0 }}>
              <div className="case-image" style={{ backgroundImage: `url(${"/data/images/caso4.jpg"})` }} />
            </Col>
            <Col sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} xl={{ span: 16, offset: 0 }}>
              <div className="case-description">
                <h3 className="case-name">Caso clínico IV</h3>
                <p className="case-title">Moderação: Dr.ª Inês Vaz Pinto</p>
                <p className="case-location">Hospital de Cascais</p>
              </div>
              <div className="case-button-wrapper">
                <Button className="case-button" type="primary" size="large" target="_blank" onClick={() => this.showModal(target.modal4)}>CLIQUE AQUI</Button>
              </div>
            </Col>
            <Divider />
            <Col sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} xl={{ span: 8, offset: 0 }}>
              <div className="case-image" style={{ backgroundImage: `url(${"/data/images/caso5.jpg"})` }} />
            </Col>
            <Col sm={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0 }} xl={{ span: 16, offset: 0 }}>
              <div className="case-description">
                <h3 className="case-name">Caso clínico V</h3>
                <p className="case-title">Moderação: Dr. Eugénio Teófilo</p>
                <p className="case-location">Hospital dos Capuchos</p>
              </div>
              <div className="case-button-wrapper">
                <Button className="case-button" type="primary" size="large" target="_blank" onClick={() => this.showModal(target.modal5)}>CLIQUE AQUI</Button>
              </div>
            </Col>
            <Divider />
          </Row>

        </div>

        <Modal
          ref={this.pdf}

          title={this.state.modal.title}
          centered
          visible={this.state.visible}
          footer={null}
          onCancel={this.hideModal}
          width={1008}
          destroyOnClose
        >
          <div >
            {this.state.modal.link !== "" ?
              <PDFViewer
                ref={this.pdf}
                document={{
                  url: this.state.modal.link,
                  // navbarWrapper:  String, // CSS Class for the Navbar Wrapper
                }}
                showThumbnail={{ scale: 2 }}
                protectContent
                css='customViewer'
                canvasCss='customCanvas'
                navigation={{
                  css: {
                    navbarWrapper: 'customWrapper',
                    zoomOutBtn: 'customPrevBtn',
                    resetZoomBtn: 'customResetBtn',
                    zoomInBtn: 'customNextBtn',
                    previousPageBtn: 'customPrevBtn',
                    pageIndicator: 'customPages',
                    nextPageBtn: 'customNextBtn',
                    rotateLeftBtn: 'customPrevBtn',
                    resetRotationBtn: 'customResetBtn',
                    rotateRightBtn: 'customNextBtn',
                  },
                }}
              /> :
              <h5>Brevemente</h5>}

          </div>
        </Modal>
      </Layout>
    );
  }
}
