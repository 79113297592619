import React, { Component } from 'react';
import { Layout } from 'antd';
import styles from './Footer.scss';
import Core from '../Core';
import {
  Link
} from "react-router-dom";


const { Footer } = Layout;

class MainFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.tableData) {
            return {
                tableData: nextProps.tableData
            }
        }
        return null;
    }

    render() {
        return (
            <Footer className="footer">
                        <div class="homepage-links">
                            {
                                <p>
                                    <div><a target="_blank" rel="noopener noreferrer" href="https://pt.viivexchange.com/termos-de-utilizacao/">Termos e Condições</a></div>
                                    <div><a target="_blank" rel="noopener noreferrer" href="https://pt.viivexchange.com/politica-de-privacidade/">Política de Privacidade</a></div>
                                    <div><a target="_blank" rel="noopener noreferrer" href="https://pt.viivexchange.com/notificar-informacao-de-seguranca/">Notificar Informação de Segurança</a></div>
                                </p>
                            }
                        </div>
              {Core.data().config.branding == true ?
                    <>
                        <img className="logo-alt" src="/data/images/logo-alt.svg" />
                        <p>©2021 empresas do grupo ViiV Healthcare ou sob licença.</p>
                        <p>VIIVHIV Healthcare, Unipessoal Lda., R. Dr. António Loureiro Borges, no 3, Arquiparque-Miraflores, 1499-013 Algés, Portugal | NIPC-509117961 | TEL: +351 21 094 08 01 | FAX: +351 21 094 09 01</p>
                        <p>Para mais informações deverá contactar o representante local do titular da AIM. VIIVHIV, Unipessoal Lda. | NP-PT-HVX-WCNT-210005 | agosto 2021</p>
                    </>
              : null}
            </Footer>
        )
    }
}

export default MainFooter;
